import { IEnvironment } from '../app/core/interfaces';

export const environment: IEnvironment = {
  version: '1.2.5',
  production: true,
  apiUrl: 'https://proflyt-api-internal.didmy.best',
  clientUrl: 'https://proflyt-dev-test.didmy.best',
  firebaseConfig: {
    apiKey: 'AIzaSyDodeP_q-rkLSRtPN7lIHrDb5m2Rr9l0B0',
    authDomain: 'proflyt.firebaseapp.com',
    databaseURL: 'https://proflyt.firebaseio.com',
    projectId: 'proflyt',
    storageBucket: 'proflyt.appspot.com',
    messagingSenderId: '853525073122',
    appId: '1:853525073122:web:eebd9909a52b1591d922bb',
    measurementId: 'G-286E18VW9Z',
  },
  appIdentifier: 'com.ProFlyt.ProFlyt',
  iosAppId: '1548088693',
  apiBaseUrl: 'https://proflyt-api-internal.didmy.best',
  // itsScrappingUrl: 'http://its-proflyt-service.proflyt-app.svc.cluster.local',
  itsScrappingUrl: 'https://its-ms-dev-test.didmy.best',
  itsScrappingToken: 'BwdeTOymZbXPxPKFoxMieZSye5nDWsRo',
  authProvider: 'rest',
  keycloakConfig: {
    realm: 'master',
    clientId: 'api-proflyt',
    url: 'http://keycloak.keycloak.svc.cluster.local',
  },

  // METREA DEMO CONFIG
  metreaDemo: {
    squadronName: 'Metrea',
  },
};
