"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateFlightTime = void 0;
const moment = require("moment-timezone");
function calculateFlightTime(takeOffTime, landingTime) {
    if (landingTime.isBefore(takeOffTime)) {
        landingTime.add(1, 'days');
    }
    return moment.duration(landingTime.diff(takeOffTime)).asHours();
}
exports.calculateFlightTime = calculateFlightTime;
