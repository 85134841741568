"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventContractDetailSchema = void 0;
const yup = require("yup");
exports.eventContractDetailSchema = yup.object().shape({
    id: yup.string().notRequired(),
    flightType: yup.string().notRequired(),
    filingType: yup.string().notRequired(),
    weekNumber: yup.string().notRequired(),
    tor: yup.string().notRequired(),
    taskingOrder: yup.string().notRequired(),
    clin: yup.string().notRequired(),
    acrn: yup.string().notRequired(),
    chargeTo: yup.string().notRequired(),
    majcomSupported: yup.string().notRequired(),
    unitSupported: yup.string().notRequired(),
    exercise: yup.string().notRequired(),
});
