import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  AllUsersAPI,
  User,
  IResponseUrl,
  FormFields,
  Squadron,
  APISuccessMessage,
  IPendingInvitations,
  SpecificationRoleType,
} from 'contracts';
import { Observable } from 'rxjs';
import { ITokenSetCredentials } from '../interfaces/token-set-credentials.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly apiUrl = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {}

  public login(email: string, password: string): Observable<{ user: User; tokenSet: ITokenSetCredentials }> {
    return this.http.post<{ user: User; tokenSet: ITokenSetCredentials }>(this.apiUrl + '/auth/login', {
      email,
      password,
    });
  }

  public refreshTokens(refreshToken: string): Observable<ITokenSetCredentials> {
    return this.http.post<ITokenSetCredentials>(this.apiUrl + '/auth/refresh', { refreshToken });
  }

  public logout(): Observable<void> {
    return this.http.get<void>(this.apiUrl + '/auth/logout');
  }

  public resetPassword(userEmail: string): Observable<void> {
    return this.http.post<void>(this.apiUrl + '/auth/reset-password', { userEmail });
  }

  public getCurrentUserInfo(): Observable<User> {
    return this.http.get<User>(this.apiUrl + '/users/current');
  }

  public getCurrentProfilePicture(userId: string): Observable<IResponseUrl> {
    return this.http.get<IResponseUrl>(`${this.apiUrl}/profiles/picture/${userId}`);
  }

  public getUserByID(userID: string): Observable<AllUsersAPI> {
    return this.http.get<AllUsersAPI>(this.apiUrl + '/users/id/' + userID);
  }

  public createNewUser(payload: User): Observable<User> {
    return this.http.post<User>(this.apiUrl + '/users', payload);
  }

  public editUser(userID: string, user: User): Observable<User> {
    return this.http.put<User>(this.apiUrl + '/users/id/' + userID, user);
  }

  public editCurrentUser(
    firstName: string,
    lastName: string,
    phoneNumber: number,
    isBiometricAuthEnabled: boolean,
    defaultTimeZone: string,
    unit: string,
    ssan: string,
    grade: string
  ): Observable<User> {
    return this.http.patch<User>(this.apiUrl + '/users/update-current', {
      firstName,
      lastName,
      phoneNumber,
      isBiometricAuthEnabled,
      defaultTimeZone,
      unit,
      ssan,
      grade,
    });
  }

  public updateCurrentUserPhoto(userId: string, file: File): Observable<IResponseUrl> {
    const ingnoreContentType = 'X-Ignore-ContentType';
    const formData = new FormData();
    formData.append(FormFields.file, file);
    const headers: HttpHeaders = new HttpHeaders().set(ingnoreContentType, '');

    return this.http.post<IResponseUrl>(`${this.apiUrl}/profiles/picture/${userId}`, formData, { headers });
  }

  public deleteUser(userID: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(this.apiUrl + '/users/id/' + userID);
  }

  public deleteUserAccount(userID: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(this.apiUrl + '/users/account/' + userID);
  }

  public inviteUser(user: User): Observable<User> {
    return this.http.patch<User>(this.apiUrl + '/users/invite', user);
  }

  public inviteAllUsers(activeSquadron: Squadron): Observable<User[]> {
    return this.http.patch<User[]>(this.apiUrl + '/users/invite-all', activeSquadron);
  }

  public areUsersWithPendingInvitations(activeSquadron: Squadron): Observable<IPendingInvitations> {
    const params = new HttpParams().set('activeSquadronId', activeSquadron.id);

    return this.http.get<IPendingInvitations>(this.apiUrl + '/users/are-pending-invitations', { params });
  }

  public setUserFirstLoginTime(): Observable<APISuccessMessage> {
    return this.http.post<APISuccessMessage>(this.apiUrl + '/users/first-login-time', {});
  }

  public updateUserTimeZone(timeZone: string): Observable<APISuccessMessage> {
    return this.http.post<APISuccessMessage>(`${this.apiUrl}/users/time-zone-offset`, { timeZone });
  }

  public firstLoginPasswordChange(newPassword: string): Observable<APISuccessMessage> {
    return this.http.put<APISuccessMessage>(`${this.apiUrl}/users/first-password-change`, { newPassword });
  }

  public resetAuthUserUnreadNotificationsCount(): Observable<APISuccessMessage> {
    return this.http.get<APISuccessMessage>(this.apiUrl + '/users/reset-unread-notifications-count');
  }

  public updateSpecificationRoles(
    userId: string,
    specificationRoles: SpecificationRoleType[]
  ): Observable<APISuccessMessage> {
    return this.http.post<APISuccessMessage>(this.apiUrl + `/users/update-specification-roles/${userId}`, {
      specificationRoles,
    });
  }
}
