"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.afto781Schema = void 0;
const yup = require("yup");
exports.afto781Schema = yup.object().shape({
    id: yup.string().notRequired(),
    date: yup.string().nullable(),
    mds: yup.string().nullable(),
    serial: yup.string().nullable(),
    unitChargedForFlyingHours: yup.string().nullable(),
    harmLocation: yup.string().nullable(),
    missionDataRows: yup.array().nullable(),
    flightAuth: yup.string().nullable(),
    issuingUnit: yup.string().nullable(),
    aircrewDataRows: yup.array().nullable(),
    maintReview: yup.string().nullable(),
    pilotReviewA: yup.string().nullable(),
    pilotReviewB: yup.string().nullable(),
    pilotReviewC: yup.string().nullable(),
    pilotReviewD: yup.string().nullable(),
    pilotReviewE: yup.string().nullable(),
    pilotReviewF: yup.string().nullable(),
    sarmReview: yup.string().nullable(),
    armsInputInitials: yup.string().nullable(),
    armsInputDate: yup.string().nullable(),
    armsAuditInitials: yup.string().nullable(),
    armsAuditDate: yup.string().nullable(),
    printedName: yup.string().nullable(),
    rank: yup.string().nullable(),
    branchOfService: yup.string().nullable(),
    dsn: yup.string().nullable(),
    signature: yup.string().nullable(),
    eventId: yup.string().nullable(),
    aircrewDataRowsReverse: yup.array().nullable(),
    remarks: yup.string().nullable(),
    isLocked: yup.boolean().notRequired(),
});
