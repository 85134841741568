"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.afto781MetreaSchema = void 0;
const yup = require("yup");
exports.afto781MetreaSchema = yup.object().shape({
    id: yup.string().notRequired(),
    date: yup.string().nullable(),
    mds: yup.string().nullable(),
    registration: yup.string().nullable(),
    homeStation: yup.string().nullable(),
    chargeTo: yup.string().nullable(),
    detachmentTdyLocation: yup.string().nullable(),
    missionNumber: yup.string().nullable(),
    techLogNumber: yup.string().nullable(),
    departIcao: yup.string().nullable(),
    arrivalIcao: yup.string().nullable(),
    engineStartTimeZ: yup.string().nullable(),
    engineStartFuel: yup.string().nullable(),
    blockOutDateZ: yup.string().nullable(),
    blockOutTimeZ: yup.string().nullable(),
    takeOffTimeZ: yup.string().nullable(),
    takeOffFuel: yup.string().nullable(),
    landingTimeZ: yup.string().nullable(),
    landingFuel: yup.string().nullable(),
    blockInDateZ: yup.string().nullable(),
    blockInTimeZ: yup.string().nullable(),
    engineShutdownTimeZ: yup.string().nullable(),
    engineShutdownFuel: yup.string().nullable(),
    flightStatisticsFlightTime: yup.string().nullable(),
    flightStatisticsBlockTime: yup.string().nullable(),
    flightStatisticsFuelOffLoad: yup.string().nullable(),
    landingsTouchAndGo: yup.string().nullable(),
    landingsFullStop: yup.string().nullable(),
    landingsTotal: yup.string().nullable(),
    aircrewDataPilotRows: yup.array().nullable(),
    aircrewDataAroRows: yup.array().nullable(),
    arTrackDataRows: yup.array().nullable(),
    reserved: yup.string().nullable(),
    totalBoomBdaContct: yup.string().nullable(),
    totalBoomBdaCycles: yup.string().nullable(),
    totalMprsContactLeft: yup.string().nullable(),
    totalMprsContactRight: yup.string().nullable(),
    totalCargoLbs: yup.string().nullable(),
    totalExtraCrewMembers: yup.string().nullable(),
    maintenanceReview: yup.string().nullable(),
    pilotReviewPao: yup.boolean().nullable(),
    pilotReviewCao: yup.boolean().nullable(),
    flightRecordsUploadDate: yup.string().nullable(),
    flightRecordsUploadName: yup.string().nullable(),
    flightRecordsAuditDate: yup.string().nullable(),
    flightRecordsAuditName: yup.string().nullable(),
    eventId: yup.string().nullable(),
    isLocked: yup.boolean().notRequired(),
});
