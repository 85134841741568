"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserIdsMock = void 0;
const user_ids_model_1 = require("./user-ids.model");
class UserIdsMock extends user_ids_model_1.UserIds {
    constructor(data) {
        const seedData = Object.assign({}, data);
        super(seedData);
    }
}
exports.UserIdsMock = UserIdsMock;
