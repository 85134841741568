"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.af3956Schema = void 0;
const yup = require("yup");
exports.af3956Schema = yup.object().shape({
    id: yup.string().notRequired(),
    asOf: yup.string().nullable(),
    nameAndGrade: yup.string().nullable(),
    ssn: yup.string().nullable(),
    organizationAndLocation: yup.string().nullable(),
    art: yup.boolean(),
    memberDataRows: yup.array().nullable(),
    atpPerformanceNumberAftp: yup.string().nullable(),
    atpPerformanceNumberAgtp: yup.string().nullable(),
    atpPerformanceForPayAftp: yup.string().nullable(),
    atpPerformanceNorPayAgtp: yup.string().nullable(),
    atpThisPeriodNumberAftp: yup.string().nullable(),
    atpThisPeriodNumberAgtp: yup.string().nullable(),
    atpThisPeriodForPayAftp: yup.string().nullable(),
    atpThisPeriodForPayAgtp: yup.string().nullable(),
    totalAtpPerformanceNumberAftp: yup.string().nullable(),
    totalAtpPerformanceNumberAgtp: yup.string().nullable(),
    totalAtpPerformanceForPayAftp: yup.string().nullable(),
    totalAtpPerformanceForPayAgtp: yup.string().nullable(),
    date: yup.string().nullable(),
    typeOrStampedNameGradeTitle: yup.string().nullable(),
    signature: yup.string().nullable(),
    eventId: yup.string().nullable(),
    isLocked: yup.boolean().notRequired(),
});
