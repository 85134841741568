"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEvalMock = void 0;
const user_eval_model_1 = require("./user-eval.model");
class UserEvalMock extends user_eval_model_1.UserEval {
    constructor(data) {
        const seedData = Object.assign({}, data);
        super(seedData);
    }
}
exports.UserEvalMock = UserEvalMock;
