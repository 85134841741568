import { createAction, props } from '@ngrx/store';
import { Squadron, CalendarEvent, EventId, User, IUserEventRelated } from 'contracts';
import { IUpdateEventId } from '../../../proflyt-root/event-id/interfaces';

export const SetUserAvailableSquadrons = createAction(
  '[Squadron] Set user available squadrons',
  props<{ squadrons: Squadron[] }>()
);

export const AddUserAvailableSquadrons = createAction(
  '[Squadron] Add user available squadrons',
  props<{ squadron: Squadron }>()
);

export const UpdateUserAvailableSquadrons = createAction(
  '[Squadron] Update one squadron in the user available squadrons list',
  props<{ squadron: Squadron; squadronId: string }>()
);

export const DeleteUserAvailableSquadrons = createAction(
  '[Squadron] Delete user available squadrons',
  props<{ squadronId: string }>()
);

export const SetSelectedSquadron = createAction(
  '[Squadron] Set selected user squadron',
  props<{ squadron: Squadron }>()
);

export const SetActiveSquadronEvents = createAction(
  '[Squadron] Set active squadron events list',
  props<{ calendarEvents: CalendarEvent[] }>()
);

export const AddActiveSquadronEvent = createAction(
  '[Squadron] Add active squadron event',
  props<{ calendarEvent: CalendarEvent }>()
);

export const ResetActiveSquadronEvents = createAction('[Squadron] Reset active squadron events list');

export const SetActiveSquadronEventsForAuthUser = createAction(
  '[Squadron] Set active squadron events list for auth user',
  props<{ calendarEvents: IUserEventRelated[] }>()
);

export const SetSquadronUsers = createAction('[Squadron] Set squadron users', props<{ users: User[] }>());

export const AddEvent = createAction(
  '[Squadron] Add calendar event',
  props<{ event: CalendarEvent | CalendarEvent[] }>()
);

export const UpdateEvent = createAction(
  '[Squadron] Update calendar event',
  props<{ event: CalendarEvent | CalendarEvent[] }>()
);

export const SetActiveSquadronEventsId = createAction(
  '[Squadron] Set active squadron events id list',
  props<{ eventsId: EventId[] }>()
);

export const AddEventId = createAction('[Squadron] Add event id', props<{ eventId: EventId }>());

export const DeleteEventId = createAction('[Squadron] Delete event id', props<{ eventIdName: string }>());

export const UpdateEventId = createAction('[Squadron] Update event id', props<{ updateEventId: IUpdateEventId }>());

export const AddOrRemoveEventRequest = createAction(
  '[Squadron] Add or remove event request',
  props<{ eventId: string; add: boolean }>()
);

export const ResetSquadronStore = createAction('[Squadron] Reset squadron store object on logout');

export const DeleteEvent = createAction('[Squadron] Delete event', props<{ eventId: string }>());

// Add user to active squadron in store
export const AddUserToActiveSquadronUsers = createAction(
  '[Squadron] Add user to active squadron users',
  props<{ user: User }>()
);

export const UpdateUserInActiveSquadronUsers = createAction(
  '[Squadron] Update user in active squadron users',
  props<{ user: User }>()
);

export const UpdateSchedulerUserEvents = createAction(
  '[Squadron] Update scheduler user events',
  props<{ schedulerUserEventRelated: IUserEventRelated[] }>()
);

export const DeleteSchedulerEvent = createAction('[Squadron] Delete user scheduler event', props<{ id: string }>());
