"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.af1522Schema = void 0;
const yup = require("yup");
exports.af1522Schema = yup.object().shape({
    id: yup.string().notRequired(),
    trainingDataRows: yup.array().nullable(),
    eSign: yup.string().nullable(),
    armsInputDate: yup.string().nullable(),
    armsInputInitials: yup.string().nullable(),
    armsAuditDate: yup.string().nullable(),
    armsAuditInitials: yup.string().nullable(),
    eventId: yup.string().nullable(),
    isLocked: yup.boolean().notRequired(),
});
