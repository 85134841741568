"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Afto781Metrea = void 0;
const afto781_metrea_schema_1 = require("./afto781-metrea.schema");
const official_form_base_model_1 = require("../official-form-base-model");
class Afto781Metrea extends official_form_base_model_1.OfficialFormBaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            aircrewDataPilotRows: [],
            aircrewDataAroRows: [],
            arTrackDataRows: [],
            pilotReviewPao: false,
            pilotReviewCao: false,
        };
    }
    getSchema() {
        return afto781_metrea_schema_1.afto781MetreaSchema;
    }
}
exports.Afto781Metrea = Afto781Metrea;
