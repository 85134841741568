"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userIdsSchema = void 0;
const yup = require("yup");
exports.userIdsSchema = yup.object().shape({
    id: yup.string(),
    flightPhysicalDueDate: yup.number().notRequired(),
    physicalAvailability: yup.string().notRequired(),
    asc: yup.string().notRequired(),
    ascTerminationDate: yup.number().notRequired(),
    physioTrainingDueDate: yup.number().notRequired(),
});
