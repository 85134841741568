import { User, RoleType, getUserPermissionValue, AuthProviderType } from 'contracts';
import { Observable, from, of, switchMap, take } from 'rxjs';
import { IAuthStrategy } from '../interfaces';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthApiService } from '../../services/auth-api.service';
import { ResetAuthState, SetAuthStatus } from '../../store-auth/actions/auth.actions';
import { IAuthState } from '../../store-auth/reducers/auth-state.reducer';
import { selectAuthState } from '../../store-auth/selectors/auth.selectors';
import { environment } from 'src/environments/environment';
import { ResetFilters } from 'src/app/super-admin/store-admin/actions/admin.actions';
import { ResetSquadronStore } from 'src/app/squadron/store/actions/squadron.actions';
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { LocalStorageKeys } from 'src/app/shared/enums';
import { ITokenSetCredentials } from '../../interfaces/token-set-credentials.interface';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class RestStrategy implements IAuthStrategy {
  public name: AuthProviderType = AuthProviderType.rest;

  constructor(
    private store: Store,
    private router: Router,
    private authApiService: AuthApiService,
    private storageService: StorageService,
    // private matDialog: MatDialog
  ) {}

  public login(email: string, password: string): Observable<User> {
    return from(this.authApiService.login(email, password)).pipe(
      switchMap(({ user, tokenSet }) => {
        this.storageService.setKey(LocalStorageKeys.TokenSet, tokenSet).pipe(take(1));

        return this.setProflytUserInStore(user);
      })
    );
  }

  public logout(shouldNotRedirect?: boolean, areTokensAlreadyRevoked = false) {
    // Remove refresh tokens on API
    if (!areTokensAlreadyRevoked) {
      this.authApiService.logout().pipe(take(1)).subscribe();
    }
    this.storageService.deleteKey(LocalStorageKeys.TokenSet).pipe(take(1));
    this.store.dispatch(ResetAuthState());
    this.store.dispatch(ResetFilters());
    this.store.dispatch(ResetSquadronStore());
    // Close all material modals
    // this.matDialog.closeAll();

    if (shouldNotRedirect) {
      return;
    }

    this.router.navigate(['/auth']);
  }

  public isAuth(): Observable<boolean> {
    return this.storageService.getKey(LocalStorageKeys.TokenSet).pipe(
      switchMap((tokenSet: ITokenSetCredentials) => {
        if (!tokenSet) {
          return of(false);
        }

        const { expiresAt } = tokenSet;

        if (expiresAt <= Date.now()) {
          this.logout();

          return of(false);
        }

        return of(true);
      })
    );
  }

  public getProflytUser(): Observable<User> {
    return this.authApiService.getCurrentUserInfo().pipe(
      switchMap((user: User) => {
        return this.setProflytUserInStore(user);
      })
    );
  }

  public resetPassword(userEmail: string): Observable<void> {
    return this.authApiService.resetPassword(userEmail);
  }

  // Verify if active user can update, delete or change role to an other user.
  public verifyUserPermissions(activeUserRoleType: RoleType, roleType: RoleType) {
    const activeUserRole: number = getUserPermissionValue(activeUserRoleType);
    const role: number = getUserPermissionValue(roleType);

    return activeUserRole >= role;
  }

  public authEvents(): void {
    // TODO: Implement
  }

  /**
   * This function will check the auth user, check the store,
   * request user info, and create local user object, only once per login/logout
   */
  private setProflytUserInStore(user: User): Observable<User> {
    return this.store.pipe(
      select(selectAuthState),
      switchMap((authState: IAuthState) => {
        if (user && authState.user) {
          // Logged in, no need for anything
          return of(authState.user);
        }

        if (!user) {
          // Not logged in
          if (authState.isLoggedIn) {
            this.store.dispatch(
              SetAuthStatus({
                isLoggedIn: false,
                user: null,
              })
            );
          }

          return of(null);
        }

        // Logged in, and no state in store
        return this.authApiService.getCurrentUserInfo().pipe(
          take(1),
          switchMap((userInfo: User) => {
            const newUser = new User({ ...userInfo });
            this.store.dispatch(
              SetAuthStatus({
                isLoggedIn: true,
                user: new User(userInfo),
              })
            );

            return of(newUser);
          })
        );
      }),
      take(1)
    );
  }
}
