"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InFlightIssueLogMetrea = void 0;
const in_flight_issue_log_metrea_schema_1 = require("./in-flight-issue-log-metrea.schema");
const official_form_base_model_1 = require("../official-form-base-model");
class InFlightIssueLogMetrea extends official_form_base_model_1.OfficialFormBaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            plannedActualMissionTimingRows: [],
            receiverInformationRows: [],
        };
    }
    getSchema() {
        return in_flight_issue_log_metrea_schema_1.inFlightIssueLogMetreaSchema;
    }
}
exports.InFlightIssueLogMetrea = InFlightIssueLogMetrea;
