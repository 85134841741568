"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Afto782Metrea = void 0;
const afto782_metrea_schema_1 = require("./afto782-metrea.schema");
const official_form_base_model_1 = require("../official-form-base-model");
class Afto782Metrea extends official_form_base_model_1.OfficialFormBaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return afto782_metrea_schema_1.afto782MetreaSchema;
    }
}
exports.Afto782Metrea = Afto782Metrea;
