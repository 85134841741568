"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserReadStanEval = exports.StanEval = void 0;
const base_model_1 = require("../base-model");
const stan_eval_schema_1 = require("./stan-eval.schema");
class StanEval extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return stan_eval_schema_1.stanEvalSchema;
    }
}
exports.StanEval = StanEval;
class UserReadStanEval extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return stan_eval_schema_1.userReadStanEvalSchema;
    }
}
exports.UserReadStanEval = UserReadStanEval;
