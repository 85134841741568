"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userEvalSchema = void 0;
const yup = require("yup");
exports.userEvalSchema = yup.object().shape({
    id: yup.string(),
    qualEval: yup.number().notRequired(),
    msnEval: yup.number().notRequired(),
    instrEval: yup.number().notRequired(),
});
