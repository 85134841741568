"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleType = void 0;
var RoleType;
(function (RoleType) {
    RoleType["SuperDevAdmin"] = "SuperDevAdmin";
    RoleType["SuperAdmin"] = "SuperAdmin";
    RoleType["ChiefAdmin"] = "ChiefAdmin";
    RoleType["Admin"] = "Admin";
    RoleType["AircrewMember"] = "AircrewMember";
})(RoleType = exports.RoleType || (exports.RoleType = {}));
