"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stanEvalSchema = exports.userReadStanEvalSchema = void 0;
const yup = require("yup");
exports.userReadStanEvalSchema = yup.object().shape({
    userId: yup.string().required(),
    readAt: yup.string().matches(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/, 'Must be ISO date string').required(),
});
exports.stanEvalSchema = yup.object().shape({
    id: yup.string().notRequired(),
    displayName: yup.string().required(),
    userId: yup.string().required(),
    squadronId: yup.string().required(),
    userName: yup.string().required(),
    userLastName: yup.string().required(),
    fileName: yup.string().required(),
    createdAt: yup.string().matches(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/, 'Must be ISO date string').required(),
    expiresAt: yup.string().matches(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/, 'Must be ISO date string').notRequired(),
    readUsers: yup.array().of(exports.userReadStanEvalSchema).required().default([]),
});
