import { RoleType, User, getUserPermissionValue, AuthProviderType } from 'contracts';
import { IAuthStrategy, IKeycloakInit } from '../interfaces';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthApiService } from '../../services/auth-api.service';
import { environment } from 'src/environments/environment';
import { Observable, from, of, switchMap, take } from 'rxjs';
import { ResetAuthState, SetAuthStatus } from '../../store-auth/actions/auth.actions';
import { ResetFilters } from 'src/app/super-admin/store-admin/actions/admin.actions';
import { ResetSquadronStore } from 'src/app/squadron/store/actions/squadron.actions';
import { KeycloakProfile } from 'keycloak-js';
import { selectAuthState } from '../../store-auth/selectors/auth.selectors';
import { IAuthState } from '../../store-auth/reducers/auth-state.reducer';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class KeycloakStrategy implements IAuthStrategy, IKeycloakInit {
  public name: AuthProviderType = AuthProviderType.keycloak;
  constructor(
    private keycloakService: KeycloakService,
    private store: Store,
    private router: Router,
    private authApiService: AuthApiService,
    // private matDialog: MatDialog
  ) {}

  public init() {
    return this.keycloakService.init({
      config: {
        url: environment.keycloakConfig.url,
        realm: environment.keycloakConfig.realm,
        clientId: environment.keycloakConfig.clientId,
      },
      initOptions: {
        onLoad: 'login-required',
      },
      loadUserProfileAtStartUp: true,
    });
  }

  public logout(shouldNotRedirect?: boolean, areTokensAlreadyRevoked = false): void {
    this.keycloakService.clearToken();
    this.store.dispatch(ResetAuthState());
    this.store.dispatch(ResetFilters());
    this.store.dispatch(ResetSquadronStore());
    this.keycloakService.logout();
    // Close all material modals
    // this.matDialog.closeAll();

    this.login();
  }

  public isAuth(): Observable<boolean> {
    return from(this.keycloakService.isLoggedIn());
  }

  public getProflytUser(): Observable<User> {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((keycloakUser: KeycloakProfile) => {
        return this.setProflytUserInStore(keycloakUser);
      })
    );
  }

  public resetPassword(): Observable<void> {
    return from(
      this.keycloakService.login({
        action: 'UPDATE_PASSWORD',
      })
    );
  }

  public verifyUserPermissions(activeUserRoleType: RoleType, roleType: RoleType): boolean {
    const activeUserRole: number = getUserPermissionValue(activeUserRoleType);
    const role: number = getUserPermissionValue(roleType);

    return activeUserRole >= role;
  }

  /**
   * This function will check the auth user, check the store,
   * request user info, and create local user object, only once per login/logout
   */
  private setProflytUserInStore(keycloakUser: KeycloakProfile): Observable<User> {
    return this.store.pipe(
      select(selectAuthState),
      switchMap((authState: IAuthState) => {
        if (keycloakUser && authState.user) {
          // Logged in, no need for anything
          return of(authState.user);
        }

        if (!keycloakUser) {
          // Not logged in
          if (authState.isLoggedIn) {
            this.store.dispatch(
              SetAuthStatus({
                isLoggedIn: false,
                user: null,
              })
            );
          }

          return of(null);
        }

        // Logged in, and no state in store
        return this.authApiService.getCurrentUserInfo().pipe(
          take(1),
          switchMap((userInfo: User) => {
            const newUser = new User({ ...userInfo });
            this.store.dispatch(
              SetAuthStatus({
                isLoggedIn: true,
                user: userInfo,
              })
            );

            return of(newUser);
          })
        );
      }),
      take(1)
    );
  }

  private login() {
    this.keycloakService.login({
      prompt: 'login',
    });
  }

  public authEvents(): void {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (event: KeycloakEvent) => {
        console.log('event', event);
        switch (event.type) {
          case KeycloakEventType.OnAuthLogout:
            console.log('logout event');
            break;
          case KeycloakEventType.OnAuthSuccess:
            console.log('logIn event');
            this.router.navigate(['/auth']);
            break;
          case KeycloakEventType.OnTokenExpired:
            this.keycloakService.updateToken(20);
            break;
          case KeycloakEventType.OnAuthRefreshSuccess:
            console.log('KeycloakEventType.OnAuthRefreshSuccess', event);
            break;
          case KeycloakEventType.OnAuthRefreshError:
            console.log('KeycloakEventType.OnAuthRefreshError', event);
            break;
          case KeycloakEventType.OnAuthError:
            console.log('KeycloakEventType.OnAuthError', event);
            break;
          case KeycloakEventType.OnReady:
            console.log('KeycloakEventType.OnReady', event);
            break;
          case KeycloakEventType.OnActionUpdate:
            console.log('KeycloakEventType.OnActionUpdate', event);
            break;

          default:
            break;
        }
      },
    });
  }
}
